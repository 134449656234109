import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  makeStyles,
  withStyles,
  Paper,
  Dialog,
  DialogContent,
  TableSortLabel,
  DialogActions,
  Button,
  Typography,
  IconButton as MaterialIconButton,
} from "@material-ui/core";
import Toggle from "./toggle";
import IconLabel from "./iconLabel";
import { globalStyles } from "../config/theme";
import { Button as IconButton, Icon as ReloadButton, Radio, RadioGroup, Dropdown, Whisper, Tooltip, Notification, Popover } from "rsuite";
import Papa from "papaparse";
import XLSX from "xlsx";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ReplayIcon from "@material-ui/icons/Replay";
import Garbage from "../icons/confirmation_modal/garbage.png";
import Dossier from "../icons/confirmation_modal/dossier.png";
import PrevPageIcon from "../icons/svg/arrow_left.svg";
import NextPageIcon from "../icons/svg/arrow_right.svg";
import FirstPageIcon from "../icons/svg/chevron_left.svg";
import LastPageIcon from "../icons/svg/chevron_right.svg";
import ExportIcon from "../icons/svg/export.svg";
import { TABLE_CONFIG } from "../config/page_config";
import { SingleInput } from "./label_input";
import EndPoints from "../webservices/endpoints";
import { useHistory } from "react-router-dom";

//Common Functions
Array.prototype.filterArray = function (input, sortKey = "", searchIn = [], primaryKey = "") {
  let val = Object.keys(input)[0];
  switch (typeof input) {
    case "string":

      if (input.length > 0) {
        return this.map(res => res[input]);
      }
    default:
      switch (Object.keys(input)[0]) {

        case "sort_by":

          switch (Object.values(input)[0]) {

            case "asc":
              return this.sort((a, b) => a[sortKey].localeCompare(b.display_name, 'en', { numeric: true }));


            case "desc":

              return this.sort((a, b) => b[sortKey].localeCompare(a.display_name, 'en', { numeric: true }));
            default:
              return this;
          }


        case (val.match(/_active/) || {}).input:
          switch (Object.values(input)[0]) {
            case true:
              return this.filter(res => res[Object.keys(input)[0]] === true);
            case false:
              return this.filter(res => res[Object.keys(input)[0]] !== true);
            default:
              return this;
          }
        case "search":
          switch (Object.values(input)[0]) {
            case "":
              return this;
            default:
              return search(this, searchIn, primaryKey, Object.values(input)[0]);
          }
        default:
          switch (Object.values(input)[0]) {
            case "":
              return this;
            case "All":
              return this;
            default:
              return this.filter(res => res[Object.keys(input)[0]] === Object.values(input)[0]);
          }
      }
  }
};

Array.prototype.search = function (searchKey) {
  if (searchKey === "") {
    return this;
  } else {
    return this.filter(obj =>
      Object.keys(obj).some(key => {
        if (typeof obj[key] === "string") {
          return obj[key].includes(searchKey);
        }
      })
    );
  }
};

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};

export const calcHeight = (percentage, margin) => {
  return window.innerHeight - window.innerHeight * (percentage / 100) - margin * 2;
};

export const calcWidth = (percentage, margin) => {
  return window.innerWidth - window.innerWidth * (percentage / 100) - margin * 2;
};

export const setPOSTPayload = (index, limit = 500) => {
  return {
    startRecIndex: index,
    limitPerPage: limit,
  };
};

export const setISODate = (inputDate, isEnd = false) => {
  let date = new Date(inputDate);
  let yr = date.getFullYear();
  let mn = date.getMonth() + 1;
  mn = mn >= 10 ? mn : "0" + mn;
  let dt = date.getDate();
  dt = dt >= 10 ? dt : "0" + dt;
  let time = isEnd ? "23:59:59.000Z" : "00:00:00.000Z";
  return `${yr}-${mn}-${dt}T${time}`;
};

export const prefOrder = (obj, mapper) => {
  var newObject = {};
  Object.keys(mapper).map(key => {
    newObject[key] = obj[key];
  });
  return newObject;
};

export const sortInput = (inputArray, mapper) => {
  let array = [];
  inputArray.length > 0 &&
    inputArray.forEach(input => {
      array.push(prefOrder(input, mapper));
    });
  return array;
};

export const unique = (array, key) => {
  return [...new Set(array.map(item => item[key]))];
};

export const uniqueObjects = (arr, primaryKey) => {
  return [...new Map(arr.map(item => [item[primaryKey], item])).values()];
};

export const search = (array, keys, primaryKey, input) => {
  if (input === "" || keys.length === 0 || primaryKey === "") {
    return array;
  }
  let temp = [];
  array.filter(record => {
    Object.entries(record).map(([key, value], i) => {
      if (keys.includes(key)) {
        if (typeof value === "number") {
          value = value.toString();
        }
        if (typeof value === "string") {
          value = value.toLowerCase();
          input = input.toLowerCase();
        }
        if (value !== null && value !== undefined && value.includes(input)) {
          temp.push(record);
        }
      }
    });
  });
  return uniqueObjects(temp, primaryKey);
};

export const parseDate = (input, length) => {
  const monthList = [
    { short: "Jan", long: "January" },
    { short: "Feb", long: "February" },
    { short: "Mar", long: "March" },
    { short: "Apr", long: "April" },
    { short: "May", long: "May" },
    { short: "Jun", long: "June" },
    { short: "Jul", long: "July" },
    { short: "Aug", long: "August" },
    { short: "Sep", long: "September" },
    { short: "Oct", long: "October" },
    { short: "Nov", long: "November" },
    { short: "Dec", long: "December" },
  ];
  let date = new Date();
  if (input.length === 12) {
    date.setFullYear(input.substring(0, 4));
    date.setMonth(input.substring(4, 6));
    date.setDate(input.substring(6, 8));
    date.setHours(input.substring(8, 10));
    date.setMinutes(input.substring(10, 12));
  } else {
    date = new Date(input.toString().replace("Z", ""));
  }
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month = monthList[date.getMonth()][length];
  let year = date.getFullYear();
  return month + " " + day + ", " + year;
};

export const parseDateRange = inputDate => {
  let date = new Date(inputDate.toString().replace("Z", ""));
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();
  let res = `${month}-${day}-${year}`;
  res = res.toString();
  return res;
};

export const getImage = url => {
  if (url === null || url === "" || url === undefined || url === "None") {
    return null;
  } else {
    if (url.includes("http")) {
      return url;
    } else if (url.startsWith("//")) {
      return "https:" + url;
    } else if (url.startsWith("/boostone")) {
      return EndPoints.CLOUD_FRONT + "public" + url;
    } else if (url.startsWith("boostone/")) {
      return EndPoints.CLOUD_FRONT + "public/" + url;
    } else if (url.startsWith("public/")) {
      return EndPoints.CLOUD_FRONT + url;
    } else {
      return null;
    }
  }
};

const CustomCheckbox = withStyles(theme => ({
  root: {
    color: "grey",
    transition: "0.1s ease-in-out",
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Checkbox color="default" {...props} />);

const Status = props => {
  const color = {
    completed: {
      color: "#32a852",
      background: "#6bfa92",
      text: "COMPLETED",
    },
    paused: {
      color: "#ff758a",
      background: "#fadadd",
      text: "PAUSED",
    },
    running: {
      color: "#198cfc",
      background: "#a8d4ff",
      text: "RUNNING",
    },
    FINISHED: {
      color: "#219653",
      background: "transparent",
      text: "FINISHED",
    },
    FAILED: {
      color: "#FF0000",
      background: "transparent",
      text: "FAILED",
    },
    STARTED: {
      color: "#DE7213",
      background: "transparent",
      text: "STARTED",
    },
  };
  return (
    <div
      style={{
        color: color.hasOwnProperty(props.value) ? color[props.value]["color"] : "",
        backgroundColor: color.hasOwnProperty(props.value) ? color[props.value]["background"] : "",
      }}
      className={color.hasOwnProperty(props.value) && useStyles.status}>
      {color.hasOwnProperty(props.value) ? color[props.value]["text"] : props.value}
    </div>
  );
};
const descendingComparator = (a, b, orderBy) => {
  let aobject = a[orderBy]
  let bobject = b[orderBy]
  if (orderBy === 'rewards'|| orderBy === 'category_id' ||  orderBy === 'display_order' || orderBy === 'locationcount' ) {
    aobject = parseInt(aobject)
    bobject = parseInt(bobject)     
  } 

    if (bobject < aobject) {
      return -1;
    }
    if (bobject > aobject) {
      return 1;
    }
    return 0;
  
};

const getComparator = (order, orderBy) => {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};



const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const setClassToRewards = (value, css) => {
  if (value.includes("+")) {
    return <div className={css.earnedPoints}>{value}</div>;
  } else if (value.includes("-")) {
    return <div className={css.negativePoints}>{value}</div>;
  } else {
    return <div>{value}</div>;
  }
};

//Styles
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
    backgroundColor: theme.palette.white,
    borderRadius: "3px",
    overflowX: "auto",
    // position: "absolute",
  },
  tableToolbar: {
    width: "100%",
    height: 50,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rightToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  leftToolbar: {
    margin: `auto 20px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  pageInput: {
    width: 30,
    borderRadius: 4,
    backgroundColor: "#F2F5FA",
    outline: "none",
    border: "none",
    textAlign: "center",
    padding: 4,
    margin: `0px 3px`,
    fontWeight: 500,
    color: "#575757",
  },
  rowsPerPageDropdown: {
    fontWeight: 400,
  },
  toolbarIcon: {
    margin: 5,
    padding: 3,
    borderRadius: 4,
    minWidth: 24,
    minHeight: 24,
    backgroundColor: "#F2F5FA",
    "&:hover": {
      cursor: "pointer",
    },
  },
  toolbarIconRight: {
    backgroundColor: "white",
    padding: 5,
    margin: 5,
    borderRadius: 4,
    minWidth: 24,
    minHeight: 24,
    "&:hover": {
      cursor: "pointer",
    },
  },
  svgIcon: {
    width: 24,
    height: 24,
    padding: 2,
  },
  iconLabelContainerStyles: {
    paddingLeft: 0,
    width: "auto",
  },
  iconLabelLabelStyles: {
    fontSize: 14,
  },
  tableContainer: {
    width: calcWidth(15, 10),
    maxHeight: calcHeight(20, 10),
  },
  tableContainerWithPagination: {
    // width: calcWidth(15, 10),
    // maxHeight: calcHeight(25, 10),
    // minWidth: 650,
  },

  row: {
    alignItems: "stretch",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.background,
    },
  },
  checkbox: {
    fontSize: 20,
  },
  cell: {
    height: 48,
    padding: "6px 16px",
  },
  cellContainer: {
    maxWidth: 120,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon: {
    fontSize: 20,
    padding: "0px 30px 0px auto",
    transition: "0.1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  largeIcon: {
    margin: "30px 10px 10px 0px",
  },
  status: {
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 10,
    fontSize: "0.7rem",
    fontWeight: 600,
  },
  whiteBack: {
    backgroundColor: "#FFFFFF",
  },
  earnedPoints: {
    fontWeight: 600,
    color: "green",
  },
  negativePoints: {
    fontWeight: 600,
    color: "red",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  actionButton: {
    fontSize: 14,
    fontWeight: 400,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Roboto",
    color: "#545964",
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 25,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.background,
    },
  },
}));

//React Component
export const TableWithPagination = props => {
  //constants
  const classes = useStyles();
  const history = useHistory();
  const globalClasses = globalStyles();

  //useState
  const [data, updateData] = useState(props.data);
  const [lastUpdated, setLastUpdated] = useState({
    time: new Date(),
    diff: "",
  });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("eventuniqueid");
  const [isTableReloading, setTableReload] = useState(false);
  const [selected, setSelected] = useState(
    props.hasOwnProperty("selected") && Array.isArray(props.selected) && props.selected.length > 0 ? props.selected : []
  );
  const [selectedPage, setSelectedPage] = useState([]);
  const [page, setPage] = useState(props.hasOwnProperty("currentObjAfterLoad") ? props.currentObjAfterLoad.page : 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.hasOwnProperty("pagination") && props.pagination === false
      ? props.data.length
      : props.hasOwnProperty("currentObjAfterLoad") && props.currentObjAfterLoad.rowsPerPage
        ? props.currentObjAfterLoad.rowsPerPage
        : TABLE_CONFIG.ROWS_PER_PAGE_OPTIONS[0]
  );
  const [totalPages, setTotalPages] = useState(Math.ceil(props.data.length / rowsPerPage));
  const [dialog, setDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    id: null,
    row: null,
    data: null,
    handler: null,
  });
  const [toolBarToHide, setToolBarToHide] = useState("");
  const [exportDialog, setExportDialog] = useState(false);
  const [batchDeleteDialog, setBatchDeleteDialog] = useState(false);
  const [exportToOptions, setExportOptions] = useState(["csv", "xlsx", "json"]);
  const [exportTo, setExportTo] = useState(exportToOptions[0]);
  const [totalItems, setTotalItems] = useState(props.totalItems);

  const actionRef = React.createRef();

  const selectAllCheckbox = useRef(null);

  //Functions
  const dialogOpen = (e, id, row, data, handler) => {
    e.stopPropagation();
    setDialogData({
      id: id,
      row: row,
      data: data,
      handler: handler,
    });
    setDialog(true);
  };

  const dialogClose = () => {
    setDialog(false);
  };

  const closeExportDialog = () => {
    setExportDialog(false);
  };

  const handleBatchDeleteDialog = state => {
    typeof state === "boolean" && setBatchDeleteDialog(state);
  };

  const handlePostDelete = newDataSet => {
    updateData(newDataSet);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePageViaInput = event => {
    if (event.target.value < 1) {
      setPage(0);
    } else if (event.target.value > totalPages) {
      setPage(totalPages - 1);
    } else {
      setPage(Math.round(event.target.value) - 1);
    }
  };

  const handlePageChangeOnClick = event => {
    let key = event.target.accessKey.toString();
    switch (key) {
      case "f": {
        page > 0 && setPage(0);
        break;
      }
      case "p": {
        page > 0 && setPage(page - 1);
        break;
      }
      case "n": {
        page + 1 < totalPages && setPage(prev => prev + 1);
        break;
      }
      case "l": {
        setPage(totalPages - 1);
        break;
      }
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
    setTotalPages(Math.ceil(data.length / parseInt(event, 10)));
  };

  const handleCheck = (event, data) => {
    if (event.target.checked) {
      !selected.includes(data[props.pageConfig.id]) && setSelected(prev => [...prev, data[props.pageConfig.id]]);
    } else {
      setSelected(prev => prev.filter(id => id !== data[props.pageConfig.id]));
    }
  };

  const handleCheckAll = event => {
    let currentPage = stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    if (event.target.checked) {
      !selectedPage.includes(page) && setSelectedPage(prev => [...prev, page]);
      currentPage.forEach(rec => !selected.includes(rec[props.pageConfig.id]) && setSelected(prev => [...prev, rec[props.pageConfig.id]]));
    } else {
      setSelectedPage(prev => prev.filter(id => id !== page));
      currentPage.forEach(rec => setSelected(prev => prev.filter(id => id !== rec[props.pageConfig.id])));
    }
  };

  const openExportDialog = () => {
    setExportDialog(true);
  };

  const handleExport = () => {
    setExportDialog(true);
    let jsonResponse = [];
    selected.map(idKey => {
      data.map(record => {
        if (record[props.pageConfig.id] === idKey) {
          jsonResponse.push(record);
        }
      });
    });
    let exportData = "";
    let options = {};
    let fileDate = new Date()
      .toGMTString()
      .replace(/[\. ,:-]+/g, "-")
      .toUpperCase();
    if (exportTo === "csv") {
      exportData = Papa.unparse(jsonResponse);
      options = { type: "text/csv;charset=utf-8;" };
      let fileData = new Blob([exportData], options);
      let fileUrl = window.URL.createObjectURL(fileData);
      let tempLink = document.createElement("a");
      tempLink.href = fileUrl;
      tempLink.setAttribute("download", `EARN-POINTS-${fileDate}.${exportTo}`);
      tempLink.click();
    } else if (exportTo === "xlsx") {
      let ws = XLSX.utils.json_to_sheet(jsonResponse);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "EARN_POINTS");
      XLSX.writeFile(wb, `EARN-POINTS-${fileDate}.${exportTo}`);
    } else if (exportTo === "json") {
      exportData = JSON.stringify(jsonResponse);
      options = { type: "application/json" };
      let fileData = new Blob([exportData], options);
      let fileUrl = window.URL.createObjectURL(fileData);
      let tempLink = document.createElement("a");
      tempLink.href = fileUrl;
      tempLink.setAttribute("download", `EARN-POINTS-${fileDate}.${exportTo}`);
      tempLink.click();
    }
  };

  const handleBatchDelete = () => {
    if (props.hasOwnProperty("handleBatchDelete")) {
      if (props.handleBatchDelete(selected)) {
        Notification["success"]({
          title: "Success",
          description: `Successfully deleted ${selected.length} records`,
        });
        handleTableReload();
      } else {
        Notification["error"]({
          title: "Failure",
          description: `Couldn't perform Batch Delete`,
        });
      }
    }
  };

  const handleTableReload = async () => {
    if (props.hasOwnProperty("handleReload")) {
      setTableReload(true);
      let res = await props.handleReload();
      if (res) {
        setTableReload(false);
        setLastUpdated(prev => ({ ...prev, time: new Date() }));
      } else {
        console.log("Error Loading the data");
        setTableReload(false);
      }
    }
  };

  const getLastUpdated = oldDate => {
    let past = new Date(oldDate);
    let present = new Date();
    let seconds = Math.floor((present - past) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    let diff = {
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
    };
    let res = "";
    if (diff.s === 0) {
      res = "0s";
    } else {
      res = "";
      Object.keys(diff).map(i => {
        if (diff[i] > 0) {
          res += `${diff[i]}${i} `;
        }
      });
    }
    setLastUpdated(prev => ({ ...prev, diff: res }));
  };

  const setCurrentPageInputWidth = () => {
    let pageInputField = document.querySelector("#pageInputField");
    pageInputField.style.width = `${30 + pageInputField.value.length * 2}px`;
  };

  const handleIconLabelClick = (path, key, value) => {
    history.push({
      pathname: path + value,
      state: { [key]: value },
    });
  };

  //useEffects
  useEffect(() => {
    props.hasOwnProperty("getSelected") && props.getSelected(selected);
  }, [selected, selectedPage]);

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  useEffect(() => {
    updateData(props.data);
  }, [props.data]);

  useEffect(() => {
    setTotalItems(props.totalItems);
  }, [props.totalItems]);

  useEffect(() => {
    setCurrentPageInputWidth();
    if (page === totalPages - 1 && props.hasOwnProperty("handleLoadMore") && data.length > 0 && data.length < totalItems) {
      let currentObj = {
        page: page,
        rowsPerPage: rowsPerPage,
      };
      props.handleLoadMore(currentObj);
    }
  }, [page, rowsPerPage]);

  useEffect(() => { }, [totalItems]);
  useEffect(() => { }, [data]);

  useEffect(() => {
    if (props.hasOwnProperty("hideToolBars")) {
      if (props.hideToolBars === "left") {
        setToolBarToHide("l");
      } else if (props.hideToolBars === "right") {
        setToolBarToHide("r");
      } else {
        setToolBarToHide("b");
      }
    } else {
      setToolBarToHide("n");
    }
  }, []);

  //Render
  return (
    <Paper className={props.hasOwnProperty("containerStyles") ? props.containerStyles : classes.root}>
      <div className={classes.tableToolbar}>
        <div
          className={classes.tableToolbar}
          style={{
            display: `${toolBarToHide === "b" ? `none` : `flex`}`,
          }}>
          <div className={classes.leftToolbar}>
            <div
              className={classes.checkbox}
              style={{
                display: `${toolBarToHide === "l" ? `none` : `block`}`,
              }}>
              <CustomCheckbox
                inputRef={selectAllCheckbox}
                disabled={props.hasOwnProperty("checkbox") ? (props.checkbox === "disabled" || props.checkbox == "Disabled" ? true : false) : true}
                checked={selectedPage.includes(page) || selected.length === totalItems}
                indeterminate={
                  selected.length > 0 &&
                  (props.hasOwnProperty("pagination") && props.pagination === false
                    ? selected.length < totalItems
                    : totalItems < rowsPerPage
                      ? selected.length < totalItems
                      : selected.length < rowsPerPage)
                }
                onClick={e => (selectAllCheckbox.current.dataset.indeterminate === "true" ? setSelected([]) : handleCheckAll(e))}
              />
            </div>
            {props.hasOwnProperty("checkbox") && Array.isArray(props.checkbox) ? (
              <div style={{ display: "flex" }}>
                {props.checkbox.map((btn, index) =>
                  !btn.isHidden ? (
                    <Whisper trigger="hover" key={`btn-${index}`} placement={"topStart"} speaker={<Tooltip>{btn.key}</Tooltip>}>
                      {btn.hasOwnProperty("isIconAComponent") && btn.isIconAComponent === true ? (
                        <btn.icon
                          handler={btn.handler.bind(null, selected)}
                          disabled={selected.length < 1}
                          reset={selected.length < 1 || btn.isReset}
                          tooltip={btn.key}
                        />
                      ) : btn.hasOwnProperty("icons") && Object.keys(btn.icons).length > 1 ? (
                        selected.length < 1 ? (
                          <MaterialIconButton className={classes.icon} onClick={() => btn.handler(selected)} disabled={selected.length < 1}>
                            {btn.icons.hasOwnProperty("disabled") && btn.icons.disabled !== undefined && <btn.icons.disabled />}
                          </MaterialIconButton>
                        ) : (
                          <MaterialIconButton className={classes.icon} onClick={() => btn.handler(selected)} disabled={selected.length < 1}>
                            {btn.icons.hasOwnProperty("active") && btn.icons.active !== undefined && <btn.icons.active />}
                          </MaterialIconButton>
                        )
                      ) : (
                        <MaterialIconButton className={classes.icon} onClick={() => btn.handler(selected)} disabled={selected.length < 1}>
                          <btn.icon />
                        </MaterialIconButton>
                      )}
                    </Whisper>
                  ) : null
                )}
              </div>
            ) : null}
            {props.hasOwnProperty("handleReload") && typeof props.handleReload === "function" ? (
              <div style={{ visibility: "visible" }}>
                <Whisper
                  trigger="hover"
                  placement={"rightStart"}
                  speaker={<Tooltip>{`Last synced ${lastUpdated.diff} ago`}</Tooltip>}
                  onMouseOver={() => {
                    getLastUpdated(lastUpdated.time);
                  }}>
                  <MaterialIconButton className={classes.icon} onClick={handleTableReload}>
                    <ReplayIcon fontSize="large" style={{ transform: "rotate(-45deg)" }} />
                  </MaterialIconButton>
                </Whisper>
              </div>
            ) : null}
          </div>
          <div
            className={classes.rightToolbar}
            style={{
              visibility: `${toolBarToHide === "r" || (props.hasOwnProperty("pagination") && props.pagination === false) ? `hidden` : `visible`}`,
            }}>
            <Typography className={globalClasses.divM5}>Rows Per Page: </Typography>
            <Dropdown title={rowsPerPage} className={classes.rowsPerPageDropdown} onSelect={handleChangeRowsPerPage} size="xs">
              {TABLE_CONFIG.ROWS_PER_PAGE_OPTIONS.map((option, i) => {
                return (
                  <Dropdown.Item key={`dropdown-item-${i}`} eventKey={option}>
                    {option}
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
            <IconButton disabled={page === 0} onClick={handlePageChangeOnClick} className={classes.toolbarIcon}>
              <img src={FirstPageIcon} accessKey="f" />
            </IconButton>
            <IconButton disabled={page === 0} onClick={handlePageChangeOnClick} className={classes.toolbarIcon}>
              <img src={PrevPageIcon} accessKey="p" />
            </IconButton>
            <Typography className={globalClasses.divM5}>Page</Typography>
            <input
              type="number"
              value={page + 1}
              onChange={handleChangePageViaInput}
              className={classes.pageInput}
              max={totalPages}
              min={1}
              step={1}
              id="pageInputField"
            />
            <Typography className={globalClasses.divM5}>{`of ${totalPages}`}</Typography>
            <IconButton disabled={page === totalPages - 1} onClick={handlePageChangeOnClick} className={classes.toolbarIcon}>
              <img src={NextPageIcon} accessKey="n" />
            </IconButton>
            <IconButton
              disabled={props.hasOwnProperty(totalItems) ? page === totalPages - 1 && data.length >= props.totalItems : page === totalPages - 1}
              onClick={handlePageChangeOnClick}
              className={classes.toolbarIcon}>
              <img src={LastPageIcon} accessKey="l" />
            </IconButton>
          </div>
        </div>
      </div>
      <TableContainer className={classes.tableContainerWithPagination} style={props.hasOwnProperty("tableHeight") ? { maxHeight: props.tableHeight } : {}}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {props.checkbox && (
                <TableCell style={{ padding: `10px 0px 10px 5px` }}>
                  <Typography style={{ width: "fit-content", margin: 0, fontSize: 12 }}>{selected.length > 0 && `Selected ${selected.length}`}</Typography>
                </TableCell>
              )}
              {/* {props.hasOwnProperty("addToggle") && props.addToggle && (
                <TableCell></TableCell>
              )} */}
              {Object.entries(props.pageConfig.header_mapper).map(([key, value], index) => {
                return (
                  <TableCell key={`rowHead-${index}`} className={classes.cell}>
                    {props.pageConfig.hasOwnProperty("toggleKey") && props.pageConfig.toggleKey.includes(key) ? (
                      <div
                        className={classes.cellContainer}
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 14,
                          color: "#6F6F6F",
                        }}>
                        {value}
                      </div>
                    ) : props.pageConfig.hasOwnProperty("no_column_sort") && props.pageConfig.no_column_sort.includes(key) ? (
                      <div
                        className={classes.cellContainer}
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 14,
                          color: "#6F6F6F",
                        }}>
                        {value}
                      </div>
                    ) : (
                      <TableSortLabel active={orderBy === key} direction={orderBy === key ? order : "asc"} onClick={createSortHandler(key)}>
                        <div
                          className={classes.cellContainer}
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 14,
                            color: "#6F6F6F",
                          }}>
                          {value}
                          {orderBy === key ? (
                            <Typography className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</Typography>
                          ) : null}
                        </div>
                      </TableSortLabel>
                    )}
                  </TableCell>
                );
              })}
              {!props.disableRowOptions && (
                <>
                  <TableCell
                    style={{
                      width: "80px",
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#6F6F6F",
                    }}></TableCell>
                  {props.hasOwnProperty("rowActions") && (
                    <TableCell
                      style={{
                        width: "80px",
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#6F6F6F",
                      }}></TableCell>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow
                    key={`Row-${i}`}
                    className={classes.row}
                    style={props.hasOwnProperty("handleRowClick") ? { cursor: "pointer" } : { cursor: "default" }}
                    onClick={e => {
                      //  e.stopPropagation();
                      if (props.hasOwnProperty("handleRowClick")) {
                        if (props.pageConfig.hasOwnProperty("second_id")) {
                          props.handleRowClick(row[props.pageConfig.id], row[props.pageConfig.second_id]);
                        } else {
                          props.handleRowClick(row[props.pageConfig.id]);
                        }
                      }
                    }}>
                    {props.hasOwnProperty("checkbox") && (
                      <TableCell className={classes.cell}>
                        <CustomCheckbox
                          disabled={props.checkbox === "disabled" || props.checkbox === "Disabled" ? true : false}
                          className={classes.checkbox}
                          checked={selected.includes(row[props.pageConfig.id])}
                          onChange={e => handleCheck(e, row)}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        />
                      </TableCell>
                    )}
                    {/* {props.hasOwnProperty("toggle") && props.toggle && (
                      <TableCell>
                        <Toggle
                          initialState={false}
                          handler={handleToggle}
                          id={row[props.pageConfig.id]}
                        />
                      </TableCell>
                    )} */}
                    {Object.keys(props.pageConfig?.header_mapper).map((propKey, inI) => {
                      let thumbnailPreview = propKey === "dbasortname" && props.pageConfig?.iconLabels?.dbasortname?.hasThumbnailView === true;
                      return (
                        <TableCell
                          key={`Cell-${i},${inI}`}
                          className={classes.cell}
                          style={{ minWidth: 20 }}
                          onClick={e => {
                            if (
                              (props.pageConfig.hasOwnProperty("toggleKey") && props.pageConfig.toggleKey.includes(propKey)) ||
                              (props.pageConfig.hasOwnProperty("inputField") && propKey === props.pageConfig.inputField)
                            ) {
                              e.stopPropagation();
                            }
                          }}>
                          {props.pageConfig.hasOwnProperty("toggleKey") && props.pageConfig.toggleKey.includes(propKey) ? (
                            <Toggle
                              initialState={row[propKey] ? row[propKey] : false}
                              handler={typeof props.handleToggle === "function" ? props.handleToggle : props.handleToggle[propKey]}
                              id={row[props.pageConfig.id]}
                            />
                          ) : props.pageConfig.hasOwnProperty("inputField") && propKey === props.pageConfig.inputField ? (
                            <SingleInput value={row[props.pageConfig.inputField]} id={row[props.pageConfig.id]} handler={props.handleInputEdit} />
                          ) : props.pageConfig.hasOwnProperty("iconLabels") && props.pageConfig.iconLabels.hasOwnProperty(propKey) ? (
                            // <div onClick={() => console.log(row)}>
                            <div
                              onClick={e => {
                                if (
                                  props.pageConfig.iconLabels[propKey].hasOwnProperty("onClickRedirect") &&
                                  props.pageConfig.iconLabels[propKey]["onClickRedirect"]["hasClickEvent"] === true
                                ) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleIconLabelClick(
                                    props.pageConfig.iconLabels[propKey]["onClickRedirect"]["path"],
                                    props.pageConfig.iconLabels[propKey]["onClickRedirect"]["key"],
                                    row[props.pageConfig.iconLabels[propKey]["onClickRedirect"]["key"]]
                                  );
                                }
                              }}>
                              <IconLabel
                                label={row[props.pageConfig.iconLabels[propKey]["name"]]}
                                logo={getImage(row[props.pageConfig.iconLabels[propKey]["logo"]])}
                                containerStyle={classes.iconLabelContainerStyles}
                                labelStyle={classes.iconLabelLabelStyles}
                                hasThumbnailPreview={thumbnailPreview}
                              />
                            </div>
                          ) : // </div>`
                            propKey === props.pageConfig.filter ? (
                              props.pageConfig.hasOwnProperty("category_mapper") && props.pageConfig.category_mapper[row[propKey]]
                            ) : row[propKey] === "" || row[propKey] === null ? (
                              <div className={classes.cellContainer}>{"-"}</div>
                            ) : props.pageConfig.hasOwnProperty("date") && props.pageConfig.date.includes(propKey) ? (
                              <div className={classes.cellContainer}>{parseDate(row[propKey].replace("Z", ""), "short")}</div>
                            ) : props.pageConfig.hasOwnProperty("image") && props.pageConfig.image.includes(propKey) ? (
                              <div className={classes.cellContainer}>
                                <img style={{ width: 144, height: 80 }} src={getImage(row[propKey])} alt="" />
                              </div>
                            ) : propKey === "status" ? (
                              <div className={classes.cellContainer}>
                                <Status value={row[propKey]} />
                              </div>
                            ) : props.pageConfig.hasOwnProperty("points") && props.pageConfig.points.hasOwnProperty(propKey) ? (
                              <div className={classes.cellContainer}>{setClassToRewards(row[propKey], classes)}</div>
                            ) : props.pageConfig.hasOwnProperty("alternate_value") && Object.keys(props.pageConfig.alternate_value).includes(propKey) ? (
                              <div className={classes.cellContainer}>{props.pageConfig.alternate_value[propKey][row[propKey]]}</div>
                            ) : (
                              <div className={classes.cellContainer}>{row[propKey]}</div>
                            )}
                        </TableCell>
                      );
                    })}
                    {!props.disableRowOptions && (
                      <>
                        <TableCell
                          onMouseOver={e => {
                            if (props.pageConfig.hasOwnProperty("provider")) {
                              if (row[props.pageConfig.provider["key"]] != props.pageConfig.provider["value"]) {
                                e.target.style.cursor = "not-allowed";
                              }
                            }
                          }}
                          onClick={e => {
                            if (props.pageConfig.hasOwnProperty("provider")) {
                              if (row[props.pageConfig.provider["key"]] != props.pageConfig.provider["value"]) {
                                e.stopPropagation();
                              }
                            }
                          }}>
                          <MaterialIconButton
                            className={classes.icon}
                            aria-label="edit"
                            disabled={
                              props.pageConfig.hasOwnProperty("provider") ? row[props.pageConfig.provider.key] != props.pageConfig.provider.value : false
                            }
                            onClick={e => props.handleEdit(e, row)}>
                            <EditIcon />
                          </MaterialIconButton>
                        </TableCell>
                        {props.hasOwnProperty("rowActions") ? (
                          <TableCell
                            onMouseOver={e => {
                              if (!props.hasOwnProperty("rowActions") && props.pageConfig.hasOwnProperty("provider")) {
                                if (row[props.pageConfig.provider["key"]] != props.pageConfig.provider["value"]) {
                                  e.target.style.cursor = "not-allowed";
                                }
                              }
                            }}
                            onClick={e => {
                              if (props.hasOwnProperty("rowActions")) {
                                e.stopPropagation();
                              }
                            }}>
                            <Whisper
                              trigger="click"
                              placement={"bottomEnd"}
                              full
                              triggerRef={actionRef}
                              speaker={
                                <Popover style={{ padding: 0 }} visible={false}>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    {props.rowActions.map((action, index) => {
                                      return (
                                        <Button
                                          key={`rowAction-${index}`}
                                          disabled={
                                            action.hasOwnProperty("isDisabled")
                                              ? action.isDisabled === null
                                                ? props.pageConfig.hasOwnProperty("provider") &&
                                                row[props.pageConfig.provider["key"]] != props.pageConfig.provider["value"]
                                                : action.isDisabled(row)
                                              : false
                                          }
                                          className={classes.actionButton}
                                          onClick={e => {
                                            e.target.parentElement.parentElement.parentElement.style.display = "none";
                                            actionRef.current.hide();
                                            action.handler(e, row);
                                          }}>
                                          {typeof action.title === "function" ? action.title(row) : action.title}
                                        </Button>
                                      );
                                    })}
                                  </div>
                                </Popover>
                              }>
                              <MaterialIconButton className={classes.icon}>
                                <MoreHorizIcon />
                              </MaterialIconButton>
                            </Whisper>
                          </TableCell>
                        ) : // (
                          //   <MaterialIconButton
                          //     className={classes.icon}
                          //     aria-label="delete"
                          //     onClick={e => dialogOpen(e, i, row, data, handlePostDelete)}
                          //     disabled={
                          //       props.pageConfig.hasOwnProperty("provider") ? row[props.pageConfig.provider.key] != props.pageConfig.provider.value : false
                          //     }>
                          //     <DeleteIcon />
                          //   </MaterialIconButton>
                          // )
                          null}
                      </>
                    )}
                  </TableRow>
                );
              })}
            {/* {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={dialog} onClose={dialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <div style={{ display: "flex" }}>
            <div className={classes.largeIcon}>
              <img src={Garbage} />
            </div>
            <div>
              <Typography variant="h6">Are you sure you want to delete?</Typography>
              <Typography variant="subtitle1">Once deleted, cannot be retrieved back</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            borderTop: "1px solid lightgrey",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Button onClick={dialogClose} color="primary" variant="outlined" className={globalClasses.buttonSecondary}>
            Oops! No Cancel
          </Button>
          <Button
            onClick={() => {
              props.handleDelete(dialogData.id, dialogData.row, dialogData.data, dialogData.handler);
              dialogClose();
            }}
            className={globalClasses.buttonPrimary}
            style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={props.hasOwnProperty("handleBatchDelete") && batchDeleteDialog}
        onClose={() => handleBatchDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <div style={{ display: "flex" }}>
            <div className={classes.largeIcon}>
              <img src={Garbage} />
            </div>
            <div>
              <Typography variant="h6">Are you sure you want to delete?</Typography>
              <Typography variant="subtitle1">{`Number of records selected : ${selected.length}`}</Typography>
              <Typography variant="subtitle1">Once deleted, cannot be retrieved back</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            borderTop: "1px solid lightgrey",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Button onClick={() => handleBatchDeleteDialog(false)} color="primary" variant="outlined" className={globalClasses.buttonSecondary}>
            Oops! No Cancel
          </Button>
          <Button
            onClick={() => {
              if (props.hasOwnProperty("handleBatchDelete")) {
                handleBatchDelete();
              }
            }}
            className={globalClasses.buttonPrimary}
            style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={exportDialog} onClose={closeExportDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <div style={{ display: "flex" }}>
            <div className={classes.largeIcon}>
              <img src={Dossier} />
            </div>
            <div>
              <Typography variant="h6">Are you sure you want to archive?</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "75%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="subtitle1">Selected records : {selected.length}</Typography>
                <div>
                  <RadioGroup inline name="radioList" value={exportTo} onChange={e => setExportTo(e)}>
                    {exportToOptions.map((option, i) => {
                      return (
                        <Radio key={`Radio-${i}`} value={option}>
                          {option}
                        </Radio>
                      );
                    })}
                  </RadioGroup>
                </div>
                <Typography variant="subtitle1" style={{ fontSize: 12 }}>
                  <b>Note</b>: Will be exported in <b>.{exportTo}</b> format
                </Typography>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            borderTop: "1px solid lightgrey",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Button onClick={closeExportDialog} color="primary" variant="outlined" className={globalClasses.buttonSecondary}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleExport();
              closeExportDialog();
            }}
            className={globalClasses.buttonPrimary}
            style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }}>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

TableWithPagination.propTypes = {
  data: PropTypes.array.isRequired,
  totalItems: PropTypes.number,
  pageConfig: PropTypes.object,
  checkbox: PropTypes.bool,
};
